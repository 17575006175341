export const defaultConfigs = {
  teleportTarget: 'body',
  modalTitle: '',
  modalActive: false,
  loading: false,
  width: '',
  maxWidth: '400',
  maxHeight: '0',
  scroll: false,
  overflowBody: true,
  contentCustomClass: ''
}
