<template>
  <Teleport :to="teleportTarget">
    <!-- <transition name="modal-animation"> -->
      <div v-if="modalActive" class="modal z-9998 popupCommon" :class="{'start': shouldFlexStart}" @keyup.esc="close">
        <div v-if="modalActive" id='popup_common'
          class="modal-inner flex flex-column"
          :class="{ 'modal-scroll': scroll, 'hasCarousel': hasCarouselSlot }"
          :style="{
            maxWidth: `${maxWidth}px`,
            maxHeight: maxHeight !== '0' ? `${maxHeight}px` : '',
          }"
        >
          <header :class="['px-20 relative', modalTitle ? 'py-22 bd-b-1 bd-gray-medium' : 'py-20']">
            <h5 class="fs-16 lh-1 fw-700">{{ modalTitle }}</h5>
            <img
              src="https://s3-cdn.designerjourneys.com/static/icons/icon-close-new.svg"
              @click.prevent="close"
              class="hand button-close"
            />
          </header>
          <slot name="carousel" />
          <div :class="['modal-content overflow-auto', hasFooterSlot ? '' : 'notFooter', modalTitle ? 'pa-20' : '', contentCustomClass ? contentCustomClass : '' ]">
            <slot name="content" />
          </div>
          <footer>
            <slot name="footer" />
          </footer>
        </div>
        <div class="modal-overlay" id="modal-overlay" @click="close" />
      </div>
    <!-- </transition> -->
  </Teleport>
</template>

<script>
import { ref, computed, watch, onMounted, onBeforeUnmount, nextTick } from 'vue'
import { defaultConfigs } from './defaults'
import { onBeforeRouteLeave } from 'vue-router'

export default {
  name: 'PopupCommon',
  emits: ['update:close'],
  props: {
    teleportTarget: {
      type: String,
      default: defaultConfigs.teleportTarget,
    },
    modalTitle: {
      required: true,
      type: String,
      default: defaultConfigs.modalTitle,
    },
    modalActive: {
      required: true,
      type: Boolean,
      default: defaultConfigs.modalActive,
    },
    loading: {
      type: Boolean,
      default: defaultConfigs.loading,
    },
    width: {
      type: String,
      default: defaultConfigs.width,
    },
    maxWidth: {
      type: String,
      default: defaultConfigs.maxWidth,
    },
    maxHeight: {
      type: String,
      default: defaultConfigs.maxHeight,
    },
    scroll: {
      type: Boolean,
      default: defaultConfigs.scroll,
    },
    overflowBody: {
      type: Boolean,
      default: defaultConfigs.overflowBody,
    },
    contentCustomClass: {
      type: String,
      default: defaultConfigs.contentCustomClass,
    },
    modalHeight: {
      type: Number,
      default: 0
    }
  },
  setup(props, { slots, emit }) {
    const close = () => {
      emit('update:close', false)
    }
    const shouldFlexStart = ref(false);
    const esc = (e) => {
      if (e.which === 27) {
        close()
      }
    }

    const hasCarouselSlot = computed(() => !!slots.carousel)
    const hasFooterSlot = computed(() => !!slots.footer)
    const offsetY = ref(0)

    watch(
      () => props.modalActive,
      async (value) => {
        if (value) {
          offsetY.value = window.pageYOffset;
          // if (props.overflowBody) {
            document.body.style.position = 'fixed'
            document.body.style.width = '100%'
            document.body.style.top = -offsetY.value + 'px'
          // }
          window.addEventListener('keydown', esc)
          await nextTick();
          shouldFlexStart.value = document.getElementById('popup_common').clientHeight > window.innerHeight;
          document.getElementById('modal-overlay').style.height = `${document.getElementById('popup_common').clientHeight + 128}px`;
        } else {
          const allPopupCommon = document.getElementsByClassName('popupCommon');
          if(allPopupCommon.length === 1)  {
            if (window.pageYOffset) {
              offsetY.value = window.pageYOffset;
            }
            // if (props.overflowBody) {
              document.documentElement.style.scrollBehavior = 'auto'
              document.body.style.position = 'static'
              window.scrollTo({
                top: offsetY.value,
                behavior: 'auto',
              })
              document.documentElement.style.scrollBehavior = 'smooth'
            // }
            window.removeEventListener('keydown', esc)
          }
        }
      },
    )

    onMounted(() =>{
      window.addEventListener('keydown', esc)
    })

    onBeforeRouteLeave(() => {
      close()
    })

    onBeforeUnmount(() => {
      window.removeEventListener('keydown', esc)
    })

    return { hasCarouselSlot, hasFooterSlot, close, shouldFlexStart }
  },
}
</script>

<style lang="scss" scoped>
.modal-animation-enter-active {
  transition: all 0.25s ease;
}
.modal-animation-leave-active {
  transition: all 0.15s ease;
}
.modal-animation-enter-from,
.modal-animation-leave-to {
  opacity: 0;
  .modal-inner {
    transform: scale(0.8);
  }
}
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-height: 100vh;
  width: 100%;
  max-width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 20px;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.4);
  &.blur {
    backdrop-filter: saturate(180%) blur(15px);
  }
  .modal-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    min-height: 100%;
    //background-color: rgba(0, 0, 0, 0.4);
  }
  .modal-inner {
    // animation: rebound 0.4s;
    box-shadow: 0px 10px 25px rgba(164, 203, 250, 0.4);
    border-radius: 14px;
    background: #fff;
    width: 100%;
    //max-height: 90vh;
    position: relative;
    z-index: 99001;
    transition: all 0.15s ease;
    /*&.modal-scroll {
      .modal-content {
        max-height: 470px;
        overflow-y: overlay;
        &::-webkit-scrollbar {
          width: 15px;
          background: transparent;
        }
        &::-webkit-scrollbar-thumb {
          border: 4px solid transparent;
          background-clip: content-box;
          background-color: $gray-medium;
          border-radius: 20px;
        }
        &.notFooter {
          margin-bottom: 20px;
        }
      }
    }*/
    /*&.hasCarousel {
      @include phone {
        max-height: 100vh;
        max-height: -webkit-fill-available;
        .modal-content {
          max-height: 100%;
          height: 100%;
        }
      }
    }*/
    header {
      .button-close {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  &.start {
    align-items: flex-start;
  }
}
@keyframes rebound {
  0% {
    transform: scale(0.8);
  }
  40% {
    transform: scale(1.04);
  }
  80% {
    transform: scale(0.96);
  }
  100% {
    transform: scale(1);
  }
}
</style>
